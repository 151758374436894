@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  animation-delay-2000 {
    animation-delay: 2s;
  }
  animation-delay-4000 {
    animation-delay: 4s;
  }
}

h1 {
  @apply text-4xl;
}
h2 {
  @apply text-3xl;
}
h3 {
  @apply text-2xl;
}
h4 {
  @apply text-xl;
}
h5 {
  @apply text-lg;
}
h6 {
  @apply text-sm;
}
.animation-delay-2000 {
  animation-delay: 2s;
}
.animation-delay-4000 {
  animation-delay: 4s;
}
html {
  @apply scroll-p-72 md:scroll-p-16;
}
body {
  @apply min-h-screen bg-gradient-to-br from-yellow-200/50 to-green-300;
}
.my-transition {
  transition: 0.3s ease-out;
}
