.def-container {
  @apply mx-auto flex max-w-full flex-col-reverse justify-evenly rounded-md bg-opacity-50 align-middle backdrop-blur-md md:flex-row md:px-12;
}
.def-left {
  @apply mx-auto flex flex-col justify-center p-2 align-middle md:w-1/3;
}
.def-right {
  @apply mx-auto p-2 md:w-1/2;
}
.card {
  @apply mb-5 rounded-lg border border-slate-400 border-opacity-20 bg-white bg-opacity-10 px-4 py-10 text-center shadow-lg backdrop-blur-xl duration-700 ease-out hover:scale-105 hover:shadow-2xl md:mb-0 md:py-16;
}
.card:hover {
  h3 {
    @apply text-green-600;
  }
}
.card {
  p {
    @apply mt-5;
  }
}
.def-service > ul {
  @apply list-image-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMzhiZGY4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42ODUuMTUzYS43NTIuNzUyIDAgMCAxIC4xNDMgMS4wNTJsLTggMTAuNWEuNzUuNzUgMCAwIDEtMS4xMjcuMDc1bC00LjUtNC41YS43NS43NSAwIDAgMSAxLjA2LTEuMDZsMy44OTQgMy44OTMgNy40OC05LjgxN2EuNzUuNzUgMCAwIDEgMS4wNS0uMTQzWiIgLz48L3N2Zz4=')];
}
.def-service > ul > li {
  @apply py-1 transition-all duration-300 ease-out hover:scale-105;
}

.formLabel {
}
.formInput {
  @apply mb-3 w-full rounded-md bg-green-200 px-4 py-3 text-sm text-gray-800 outline-green-500 focus:bg-green-100;
}
