.tiptap {
  @apply mt-2 rounded border border-stone-400 p-4;
}
.is-active {
  @apply bg-purple-600 text-white;
}
.button-group-textarea > button,
input[type="number"] .color-selector {
  @apply mx-1 my-1 rounded border border-slate-400 px-2;
}
/* .button-group-textarea > ul, */
.button-group-textarea > li {
  @apply list-inside list-disc;
}
.button-group-textarea > li > p {
  @apply m-0;
}

.tiptap {
  :first-child {
    margin-top: 0;
  }

  ul ul::marker {
    color: red; /* Change color of sub-bullet to red */
  }
  /* List styles */
  ul {
    @apply list-image-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMzhiZGY4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42ODUuMTUzYS43NTIuNzUyIDAgMCAxIC4xNDMgMS4wNTJsLTggMTAuNWEuNzUuNzUgMCAwIDEtMS4xMjcuMDc1bC00LjUtNC41YS43NS43NSAwIDAgMSAxLjA2LTEuMDZsMy44OTQgMy44OTMgNy40OC05LjgxN2EuNzUuNzUgMCAwIDEgMS4wNS0uMTQzWiIgLz48L3N2Zz4=')];
  }
  ol {
    @apply list-decimal;
  }
  ul,
  ol {
    padding: 0 1.2rem;
    margin: 0.5rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    text-wrap: pretty;
    font-family: "Poppins", sans-serif;
  }

  h1 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.7rem;
  }

  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
    margin-top: 3.5rem;
    margin-bottom: 1.4rem;
  }

  h4 {
    font-size: 1.2rem;
    margin-top: 3.5rem;
    margin-bottom: 1.3rem;
  }
  h5,
  h6 {
    font-size: 1rem;
    margin-top: 3.5rem;
    margin-bottom: 1.2rem;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 0.93rem;
    font-style: normal;
  }

  /* Code and preformatted text styles */
  code {
    background-color: rgba(118, 5, 255, 0.074);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.87rem;
    padding: 0.25em 0.3em;
  }

  pre {
    @apply my-2 overflow-auto rounded bg-slate-800 p-3 text-white;
    font-family: "JetBrainsMono", monospace;

    code {
      background: none;
      color: inherit;
      font-size: 1rem;
      padding: 0;
    }
  }

  blockquote {
    @apply rounded border-l-4 border-stone-500 bg-slate-100 p-1;
    padding-left: 1rem;
    margin: 1.5rem 0;
  }

  hr {
    margin: 2rem 0;
    @apply border border-slate-400 bg-slate-400;
  }
  a {
    @apply cursor-pointer text-blue-700 underline;
  }
  mark {
    background-color: #faf594;
    border-radius: 0.4rem;
    box-decoration-break: clone;
    padding: 0.1rem 0.3rem;
  }
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      @apply border-2 border-slate-700;
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: var(--gray-1);
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: var(--gray-2);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: var(--purple);
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}
